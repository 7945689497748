.navigation-icons-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 1rem;
}

.wow {
  visibility: hidden;
}


.responsiveNavigationIcon  {
    display: inline-block;
    margin: 0 5px;
    color: inherit;
    text-decoration: none;
    flex: none;
  }
  
  .responsiveNavigationIcon:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .tooltip-inner-navigation-styles {
    background-color: #fff;
    color: #878787;
    border-radius: 4px;
    font-weight: 700;
  
  }

  @media (max-width: 450px) {
    .responsiveNavigationIcon {
      font-size: 32px;
    }
  }
  
  