.photo-card {
  max-width: 600px;
  margin: auto; /* Center the card */
  padding-top: 5em;
  margin-bottom: 10em;
}

.photo-card img {
  width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer; /* Change cursor to pointer when hovering over the image */
}

.photo-card .caption {
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  padding: 8px 0;
}

@media (max-width: 600px) {
  .photo-card .caption {
    font-size: 15.5px;
  }

  .photo-card img {
    width: 89.9vw;
  }
}

.general-navigation-icon-div {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 6.5em;
  margin-bottom: 7.5em;
}
.general-navigation-icon {
  display: inline-block;
  margin: 0 2px;
  color: inherit;
  text-decoration: none;
  flex: none;
  cursor: pointer;
}

.general-navigation-icon:hover {
  opacity: 0.5;
  cursor: pointer;
}
.general-navigation-icon:active {
  opacity: 1;
}


@media (max-width: 450px) {
  .general-navigation-icon  {
    font-size: 15px;
  }
}
